import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from '../login.module.sass';
import { Icon } from '../../../../Icon';
import { IconSVG } from '../../../../SpriteSVG';
import { Anchor, Text } from '../../../../Text';

export const LoginAttempts = observer(({ isMobile }) => (
  <>
    <Text bottomGap={8}>
      Закончились попытки входа. <br />
      Просим обратиться за помощью к менеджеру по телефону{' '}
      <b>+7 499 955-46-01</b>.
    </Text>
    <div className={styles.contactsWhatsApp}>
      <Icon
        icon={<IconSVG name={'social/wa'} width={24} height={24} />}
        title="whatsapp"
      />
      <Anchor
        href="https://wa.clck.bar/79258328975"
        variant="text-4"
        color="primaryColor"
      >
        Написать WhatsApp
      </Anchor>
    </div>
  </>
));
