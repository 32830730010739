import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import NumberFormat from 'react-input-mask';
import { useInterval } from 'react-use';
import { SmartCaptcha } from '@yandex/smart-captcha';
import cc from 'classcat';
import { useAuthStore } from '../../../../../providers/RootStoreProvider';
import { Button } from '../../../../Button';
import { Input } from '../../../../Input';
import styles from '../login.module.sass';

const AutoSubmitToken = () => {
  const store = useAuthStore();
  const { setFieldError } = useFormikContext();

  useInterval(
    () => {
      if (!store.phone) {
        return;
      }
      if (store.toggledModal) {
        store.getPhonePolling(store.phone);
      }
    },
    store.toggledModal ? 5000 : null,
  );

  useEffect(() => {
    setFieldError('code', store.error?.message ?? '');
  }, [store.error]);

  return null;
};

export const SecondStep = observer(() => {
  const store = useAuthStore();
  const [token, setToken] = useState(null);
  const [captchaCount, setCaptchaCount] = useState(0);

  const phone = store?.formatPhone;
  const setShowCaptcha = count => store.setShowCaptcha(count);
  const { showCaptcha } = store;
  const retrySendCode = () => store.retrySendCode(token);

  useEffect(() => {
    store.getShowCaptcha();
  }, []);

  const sendRetryCode = () => {
    if (token) {
      retrySendCode();
    } else if (captchaCount < 1) {
      retrySendCode();
    }
  };

  const authByCode = code => store.authByCode(code);

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      onSubmit={async (values, { setFieldError }) => {
        try {
          await authByCode(values.code);
        } catch (error) {
          setFieldError('code', 'Не нашли номер, попробуйте еще раз!');
        }
      }}
    >
      {({ values, setFieldValue, touched, errors }) => (
        <Form>
          <Input
            id="code"
            control={NumberFormat}
            values={values.code}
            placeholder="Введите код"
            label="Введите код"
            maskChar=""
            autoComplete="off"
            onChange={e => {
              setFieldValue('code', e.target.value);
              window.ym(35441960, 'reachGoal', 'authorization_sms');
            }}
            error={touched.code && errors.code}
          />
          <AutoSubmitToken />
          <div className={styles.checkCode}>
            <span>
              Мы отправили вам код на номер <br /> <b>{phone}</b>.{' '}
            </span>
            <br />
            <Button size="lg" fullWidth topGap type="submit">
              Продолжить
            </Button>
            <br />
            <Button
              view="text"
              onClick={() => {
                setCaptchaCount(captchaCount + 1);
                setShowCaptcha(captchaCount);
                sendRetryCode();
                setToken(null);
              }}
              className={cc([{ [styles.disabled]: !token && showCaptcha }])}
            >
              Отправить новый код
            </Button>
            {showCaptcha && (
              <SmartCaptcha
                key={captchaCount}
                sitekey="ysc1_L95zJFarTqHBIm800oQupSJHIRSYv2U2WZc6HeHic61c272c"
                onSuccess={setToken}
              />
            )}
            <br />
            <Button
              view="text"
              onClick={() => {
                if (store.strategySignIn !== 'authSteps') {
                  store.setStep('phoneInput');
                } else {
                  store.setToggledModal(false);
                }
              }}
            >
              Изменить номер
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
});
