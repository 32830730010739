import React from 'react';
import cc from 'classcat';
import { useRouter } from 'next/router';
import { Anchor, Text } from '../../Text';
import styles from './loginMenu.module.sass';
import { useAuthStore } from '../../../providers/RootStoreProvider';
import { checkedDev } from '../../../utils/checkedDev';

export const LoginMenu = ({ onClick, isManager, isMobile, urlBF }) => {
  const store = useAuthStore();
  const router = useRouter();

  return (
    <div
      className={cc([
        [styles.wrapper],
        {
          [styles.wrapperBf]: urlBF === 'black_friday',
        },
      ])}
    >
      <div className={styles.item}>
        <div className={styles.orders}>
          <Anchor
            variant="text-7"
            color={
              router.route !== '/my/orders' ? 'primaryColor' : 'dark-color'
            }
            href="/my/orders"
            onClick={() => onClick()}
            className={urlBF === 'black_friday' && styles.textItem}
          >
            Мои заказы
          </Anchor>
          {/*  <div className={styles.ordersCount}>
            <Text
              variant="text"
              color={
                router.route !== '/my/orders' ? 'primaryColor' : 'dark-color'
              }
            >
              {count}
            </Text>
          </div> */}
        </div>
        <div>
          <Anchor
            variant="text-7"
            color={router.route !== '/my/main' ? 'primaryColor' : 'dark-color'}
            href="/my/main"
            onClick={() => onClick()}
            className={urlBF === 'black_friday' && styles.textItem}
          >
            Мой профиль
          </Anchor>
        </div>
        {isManager && (
          <div>
            <Anchor
              variant="text-7"
              color="primaryColor"
              href={`${checkedDev('/adm_n/index.html#!')}`}
              onClick={() => onClick()}
              className={urlBF === 'black_friday' && styles.textItem}
            >
              Админка
            </Anchor>
          </div>
        )}
        <div>
          <Anchor
            variant="text-7"
            color="primaryColor"
            href="/reviews"
            onClick={() => onClick()}
            className={urlBF === 'black_friday' && styles.textItem}
          >
            Отзывы
          </Anchor>
        </div>
        <div className={styles.buttonWrapper}>
          <Text
            variant="text-7"
            color="primaryColor"
            onClick={() => {
              store.logOut();
              onClick();
            }}
            className={urlBF === 'black_friday' && styles.textItem}
          >
            Выход
          </Text>
        </div>
      </div>
    </div>
  );
};
