import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from '../login.module.sass';
import { useAuthStore } from '../../../../../providers/RootStoreProvider';
import { Button } from '../../../../Button';
import { Text } from '../../../../Text';

export const LackPersonalArea = observer(() => {
  const store = useAuthStore();
  return (
    <>
      <Text bottomGap={8}>
        У вас пока нет личного кабинета.
        <br />
        Забронируйте круиз, чтобы его создать, или введите номер, который вы
        использовали при бронировании.
      </Text>
      <div className={styles.contactsWhatsApp}>
        <Button
          view="text"
          onClick={() => {
            store.setStep('phoneInput');
          }}
        >
          Изменить номер
        </Button>
      </div>
    </>
  );
});
