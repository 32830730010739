import { isClient } from './isClient';

export const checkedDev = url => {
  console.log('@@@ location', isClient && window?.location.hostname);
  if (process.env.NODE_ENV === 'development') {
    return `https://test.kruiz.tech${url}`;
  }
  if (isClient && window?.location.hostname === 'test.kruiz.tech') {
    console.log('@@@ tech');
    return `https://test.kruiz.tech${url}`;
  }
  return `https://kruiz.online${url}`;
};
