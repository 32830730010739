// TODO https://gist.github.com/Kafkae/e9f58fae735961e688319fab6a4fe7d8
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { observer } from 'mobx-react-lite';
import NumberFormat from 'react-input-mask';
import * as Yup from 'yup';
import { useInterval } from 'react-use';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { useAuthStore } from '../../../../../providers/RootStoreProvider';
import { unFormatterPhone } from '../../../../../utils/unFormatterPhone';
import { Button } from '../../../../Button';
import { Input } from '../../../../Input';
import styles from '../login.module.sass';
import { Text } from '../../../../Text';

const LoginSchema = Yup.object().shape({
  phone: Yup.string().trim().min(11, '').max(11, '').required('Required'),
});

export const FirstStep = observer(() => {
  const store = useAuthStore();
  const [token, setToken] = useState(null);

  const setPhone = (phone, typePhone) => store.setPhone(phone, typePhone);

  useInterval(
    () => {
      store.getPhonePolling(store.phone);
    },
    store.phone ? 5000 : null,
  );

  const getCode = () => store.getCode(token);

  return (
    <Formik
      onSubmit={async (values, { setFieldError }) => {
        try {
          setPhone(values.phone, '');
          setPhone(values.formatPhone, 'formatPhone');
          await getCode();
        } catch (error) {
          setFieldError('phone', 'Не нашли номер, попробуйте еще раз');
        }
      }}
      validationSchema={LoginSchema}
      initialValues={{
        phone: '',
        formatPhone: '',
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => (
        <Form>
          <Text variant="famyle" bottomGap={32}>
            Бронируя круиз, вы автоматически регистрируетесь на сайте
          </Text>
          <Input
            id="phone"
            control={NumberFormat}
            values={values.phone}
            mask="+7 999 999-99-99"
            autoComplete="off"
            placeholder="Номер телефона"
            label="Номер телефона"
            maskChar=""
            onChange={e => {
              setFieldValue('formatPhone', e.target.value);
              setFieldValue('phone', unFormatterPhone(e.target.value));
            }}
            error={touched.phone && errors.phone}
          />
          {store.toggledModal && (
            <>
              <div className={styles.captcha}>
                <SmartCaptcha
                  sitekey="ysc1_L95zJFarTqHBIm800oQupSJHIRSYv2U2WZc6HeHic61c272c"
                  onSuccess={setToken}
                />
              </div>
            </>
          )}
          <Button
            size="lg"
            fullWidth
            topGap
            type="submit"
            disabled={!(isValid && dirty) || isSubmitting || !token}
          >
            {isSubmitting ? 'Ищем номер' : 'Продолжить'}
          </Button>
        </Form>
      )}
    </Formik>
  );
});
